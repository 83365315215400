import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ErrorStateMatcher, MatOptionSelectionChange} from '@angular/material/core';
import { AuthService } from '../service/auth.service';
import { ToasterServiceService } from '../service/toaster-service.service';
import { TokenService } from '../service/token.service';
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  username = '';
  password = '';
  isLoadingResults = false;
  matcher = new MyErrorStateMatcher();
  constructor(    private token: TokenService,private toaster: ToasterServiceService,     private authService: AuthService,private router: Router, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username : [null, Validators.required],
      password : [null, Validators.required]
    });
  }
  onFormSubmit(): void {
    debugger
    
    this.isLoadingResults = true;
    this.authService.login(this.loginForm.value)
      .subscribe(() => {
        this.isLoadingResults = false;
        this.router.navigate(['/dashboard']).then(_ => console.log('You are secure now!'));
      }, (err: any) => {
        this.token.showToastMSG(err, '', 3000, 'error')
        
        console.log(err);
        this.isLoadingResults = false;
      });
  }
  getUrl()
  {
    return "url(assets/img/login.jpg)";
  }
  onlyNumberkeyPress(event: any) {

    const pattern = /[0-9\0\0\ ]/;

    let inputChar = String.fromCharCode(event.charCode);
    // console.log(inputChar)
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
    if (event.which === 32) {
      return false;
    }
    return true;

  }
}

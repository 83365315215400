
  
  <section class="login-block banner-sec abc" [ngStyle]="{'background-image': getUrl()}" >

          <div class="login-sec">
            <div class="col-sm-12 paddingClass"><img src="assets/img/eAMS_Black.png" class="logo_class"></div>
              <h2 class="text-center">Register Now</h2>
              <form [formGroup]="registerForm" (ngSubmit)="onFormSubmit(registerForm.value)">
                <div class="form-group">
                  <label for="exampleInputEmail1">Organization Name <span class="labelIcon">&nbsp;*</span></label>
                  <input type="text" class="form-control" maxlength="100" placeholder="Organization Name" formControlName="organizationName"
                >
                  
                </div>
                <div class="form-group">
                  <label for="exampleInputPassword1">Organization Type <span class="labelIcon">&nbsp;*</span></label>
                  <select id="type" class="form-control" (change)="getOrganizationType($event.target.value)"> 
                    <option>Select Type</option>
                    <option>Coaching</option>
                    <option>College</option>
                    <option>School</option>
                    <option>Corporate</option>
                </select>
</div>
                <div class="form-group">
                  <label for="exampleInputEmail1" >Email Id <span class="labelIcon">&nbsp;*</span></label>
                  <input type="text" class="form-control" placeholder="Email Id" formControlName="emailId" maxlength="100"
                >
                  
                </div>
             
                <!-- <label class="focus-label">Designation</label> -->

<!-- 
                <div class="form-group form-focus select-focus">
                  <select class="select floating"> 
                    <option>Select Designation</option>
                    <option>Web Developer</option>
                    <option>Web Designer</option>
                    <option>Android Developer</option>
                    <option>Ios Developer</option>
                  </select>
                  <label class="focus-label">Designation</label>
                </div> -->
               
                <div class="form-group">
                  <label for="exampleInputPassword1" >Subtitle</label>
                  <input type="text" class="form-control" placeholder="Subtitle" formControlName="organizationSubTitle" maxlength="100"
                 >
                </div>

                <div class="form-group">
                  <label for="exampleInputEmail1" >Remarks</label>
                  <input type="text" class="form-control" placeholder="Remarks" formControlName="remarks" maxlength="100"
                >
                  
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1" >Organization URL </label>
                  <input type="text" class="form-control" placeholder="Organization URL" formControlName="organizationLogoUrl" maxlength="100"
                >
                  
                </div>
    <div class="form-group">
      <label for="exampleInputEmail1">Fax Number</label>
      <input type="text" class="form-control" placeholder="Fax Number" formControlName="faxNumber" maxlength="100"
    >
      
    </div>
    <div class="form-group">
      <label for="exampleInputEmail1">Address <span class="labelIcon">&nbsp;*</span></label>
      <input type="text" class="form-control" placeholder="Address" formControlName="address" maxlength="100"
    >
      
    </div>

    <!-- <div class="form-group">
      <label for="exampleInputEmail1">Display Name <span class="labelIcon">&nbsp;*</span></label>
      <input type="text" class="form-control" placeholder="Display Name" formControlName="loginUser" maxlength="100"
    >
      
    </div> -->

    <div class="form-group">
      <label for="exampleInputEmail1" >Phone Number <span class="labelIcon">&nbsp;*</span></label>
      <input type="text" formControlName="phoneNumber" class="form-control" required
      maxlength="10"  (keypress)="onlyNumberkeyPress($event)" (paste)="(false)"
    >
      
    </div>
    <div class="form-group">
      <label for="exampleInputEmail1 ">Password <span class="labelIcon">&nbsp;*</span></label>
      <input type="password" class="form-control" placeholder="Password" formControlName="password" maxlength="100"
    >
      
    </div>
  
    
    
      <div class="form-check">
   
      <button type="submit" class="btn btn-login float-right">Submit</button>
    </div>
    
  </form>
  <p class="pclassData">Already registered?
    
    <a [routerLink]="['/login']">Login</a>
   </p>
  </div>

  </section>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../service/auth.service';
import { GroupServiceService } from '../service/group-service/group-service.service';
import jwt_decode from "jwt-decode";
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  token:any;
  decode:any;
  organizationId:any;
  constructor(private authService: AuthService, private router: Router, private groupService:GroupServiceService) { 
    this.token= localStorage.getItem("access_token");
    var decoded = jwt_decode(this.token);
     this.decode = JSON.parse(JSON.stringify(decoded));
     this.organizationId=this.decode.organizationId
  }

  ngOnInit(): void {
    this.getByIdOrganization();
  }
  logout(): void {
    this.authService.logout();
    this.router.navigate(['/login']).then(_ => console.log('Logout'));
  }
  organization(){
    this.router.navigate(['/organization'])
  }
organizationName:any
// organization
  getByIdOrganization()
  {
    debugger
    this.groupService.getOrganizationId(this.organizationId).subscribe(
      (data: any) => {
console.log(data)
this.organizationName=data.output.data.organizationName;



      })  
  }
}

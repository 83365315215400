import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
const ACCESS_TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token';
@Injectable({
  providedIn: 'root'
})

export class TokenService {

  constructor(private toastr: ToastrService) { }
  getToken(): string {
    return localStorage.getItem(ACCESS_TOKEN) || '';
  }

  getRefreshToken(): string {
    return localStorage.getItem(REFRESH_TOKEN) || '';
  }

  saveToken(token: string): void {
    localStorage.setItem(ACCESS_TOKEN, token);
  }

  saveRefreshToken(refreshToken: string): void {
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  }

  removeToken(): void {
    localStorage.removeItem(ACCESS_TOKEN);
  }

  removeRefreshToken(): void {
    localStorage.removeItem(REFRESH_TOKEN);
  }

  showToastMSG(message, title, timespan, type) {
   
    if (message == '' || message == null || message == undefined) {
      return;

    }
    if (title == '' || title == null || title == undefined) {
      title = 'System Message !';

    }
    if (timespan == '' || timespan == null || timespan == undefined) {
      timespan = 3000;

    }
    if (type == '' || type == null || type == undefined) {
      title = 'info';

    }
    if (type == 'success') {
      this.toastr.success(message, title, {
        timeOut: timespan,
        enableHtml: true
      })
    }
    if (type == 'error') {
      this.toastr.error(message, title, {
        timeOut: timespan,
        enableHtml: true
      })
    }
    if (type == 'warn') {
      this.toastr.warning(message, title, {
        timeOut: timespan,
        enableHtml: true
      })
    }
    if (type == 'info') {
      this.toastr.info(message, title, {
        timeOut: timespan,
        enableHtml: true
      })
    }
  }
  
}
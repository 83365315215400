import { Component, OnInit } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../service/auth.service';
import { TokenService } from '../service/token.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  username = '';
  password = '';
  name = '';
  isLoadingResults = false;
  matcher = new MyErrorStateMatcher();

  constructor(private authService: AuthService, private router: Router, private formBuilder: FormBuilder,private authenticate:TokenService) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      organizationName: [null, Validators.required],
      organizationType: [null, Validators.required],
      remarks: [null, Validators.required],
      organizationLogoUrl: [null, Validators.required],
      organizationSubTitle: [null, Validators.required],
      website: [null, Validators.required],
      faxNumber: [null, Validators.required],
      address: [null, Validators.required],
      password: [null, Validators.required],
      name: [null, Validators.required],
      loginUser: [null, Validators.required],
      displayName: [null, Validators.required],
      emailId: [null, Validators.required],
      phoneNumber: [null, Validators.required],

    });
  }
  getOrganizationData: any;
  getOrganizationType(val: any) {

    this.getOrganizationData = val;

  }

  onlyNumberkeyPress(event: any) {

    const pattern = /[0-9\0\0\ ]/;

    let inputChar = String.fromCharCode(event.charCode);
    // console.log(inputChar)
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
    if (event.which === 32) {
      return false;
    }
    return true;

  }
  getNoOfRecords() {
    debugger;
    var selectElement: any
    selectElement = document.querySelector('#paginationForExams');
    var noFoRecord = 0;
    noFoRecord = selectElement['value'];
  }
  checkInstituteName: any = { isValid: false, inValidMessage: '' };
  onFormSubmit(json): void {
    debugger
    this.isLoadingResults = true;
    // var body={
    json.displayName = json.loginUser;
    json.organizationType = this.getOrganizationData;

    // }


    if (json.organizationName == null || json.organizationName.trim() === '' || json.organizationName === '') {
      this.authenticate.showToastMSG(
      "Organization name is required!",
        "",
        null,
        "error"
      );
      return
    }

    if (json.organizationType == null || json.organizationType.trim() === '' || json.organizationType == 'Select Type') {
      this.authenticate.showToastMSG(
        "Organization Type is required!",
          "",
          null,
          "error"
        );
      return
    }
    this.isEmail();
    if (this.isCgeckData == false) {
  
        this.authenticate.showToastMSG(
          "Email is not valid!",
            "",
            null,
            "error"
          );
      return;
    }



    if (json.address == null || json.address.trim() === '' || json.address === '') {
      this.authenticate.showToastMSG(
        "Address is required!",
          "",
          null,
          "error"
        );
      // alert("Address is null")
      return
    }
    // if (json.displayName == null || json.displayName.trim() === '' || json.displayName === '') {
    //   this.authenticate.showToastMSG(
    //     "Display name is required!",
    //       "",
    //       null,
    //       "error"
    //     );
    //   return
    // }
    if (json.phoneNumber == null || json.phoneNumber.trim() === '' || json.phoneNumber === '') {
      this.authenticate.showToastMSG(
        "Phone Number is required!",
          "",
          null,
          "error"
        );
      return
    }
    if (!(typeof +json.phoneNumber === "number" && !isNaN(+json.phoneNumber))) {
      this.authenticate.showToastMSG(
        "Only numbers  allowed in contact number  field!",
          "",
          null,
          "error"
        );
      return;
    }
    if (json.password == null || json.password.trim() === '' || json.password === '') {
      this.authenticate.showToastMSG(
        "Password is required",
          "",
          null,
          "error"
        );
      return
    }
    this.authService.register(this.registerForm.value)
      .subscribe((res: any) => {
        if (res['output'].statusCode === "200") {
        this.isLoadingResults = false;
        this.authenticate.showToastMSG(
          "Registered successfully",
            "",
            null,
            "success"
          );
        this.router.navigate(['/login']).then(_ => console.log('You are registered now!'));
        }
//        else if (res['output']['statusCode'] == '400')
//         {
// alert("Email Id is already registered!")
//         }
        else if(res['output']['statusCode'] == '400')
        {
this.authenticate.showToastMSG(
  "Contact number is already registered!",
    "",
    null,
    "error"
  );
        }
        else if(res['output']['statusCode'] == '402')
        {

this.authenticate.showToastMSG(
  "Error in username",
    "",
    null,
    "error"
  );
        }
        else if(res['output']['statusCode'] == '415')
        {

this.authenticate.showToastMSG(
  "Username is already registered!",
    "",
    null,
    "error"
  );
        }

        else if(res['output']['statusCode'] == '410')
        {

this.authenticate.showToastMSG(
  "Email already registered!",
    "",
    null,
    "error"
  );
        }
      }, (err: any) => {
        console.log(err);
        this.isLoadingResults = false;
      });
  }
  isCgeckData: boolean = true;
  isEmail() {

    var searchFind: boolean;
    // this.checkEmail = { isValid: false, inValidMessage: '' };
    if (this.registerForm.controls['emailId'].value != '') {
      var regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

      searchFind = regexp.test(this.registerForm.controls['emailId'].value);

      console.log(searchFind);
      this.isCgeckData = searchFind;

    }
  }

  getUrl()
  {
    return "url(assets/img/login.jpg)";
  }
}
<div class="example-container mat-elevation-z8">
    <!-- <div class="example-loading-shade"
         *ngIf="isLoadingResults">
      <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    </div> -->
    <mat-card class="example-card">
      <h4>Employee</h4>
      <!-- <table class="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">employeeId</th>
            <th scope="col">employeeName</th>
            <th scope="col">employeeGender</th>
            <th scope="col">employeePhone</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of message; index as i; ">
            <th scope="row">{{i+1}}</th>
            <th >{{item.employeeId}}</th>
            <td>{{item.employeeName}}</td>
            <td>{{item.employeeGender}}</td>
            <td>{{item.employeePhone}}</td>
          </tr>
          
        </tbody>
      </table> -->


      <div class="button-row">
        <a mat-flat-button color="primary" (click)="logout()"><mat-icon>logout</mat-icon>Logout</a>
      </div>
    </mat-card>
  </div>